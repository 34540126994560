import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <>
      <p>I'm the NotFound page component</p>
      <Link to="/">asdfasdfs</Link>
    </>
  )
}

export default NotFound
